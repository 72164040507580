import React from 'react';
import Layout from '../components/layout';

const ManagementPage = () => {
  return (
    <Layout pageTitle="Management Details" title="MRC Agrotech Ltd - Management">
      <div className="row text-center">
        <div className="col-12 mb-4" data-sal="zoom-in" data-sal-easing="ease-out">
          <div className="team-member">
            <h2 className="client-heading">
              MR. UTTAM KUMAR SINGH<span>Director</span>
            </h2>
            <p>
              Born in Uttar Pardesh, Mr. Uttam did his Graduation from the renowned University of Jaunpur U.P. Mr. Uttam
              has spent over 15 years in the industry where he has worked for Dairy and Agriculture Project while
              exploring the ground realities of the existing Indian Agriculture system, he witnessed the travails of the
              farmers affected by climate change, exploitation by the middle-men, and deaths due to low or no-income,
              first hand. This experience made his dream of working towards the upliftment of the farmer community a
              driving force for his company - MRC Agrotech Ltd
            </p>
          </div>
        </div>
        <div className="col-12 mb-4" data-sal="zoom-in" data-sal-easing="ease-out">
          <div className="team-member">
            <h2 className="client-heading">
              MR. SADANANDAN A C<span>Director</span>
            </h2>
            <p>
              40 Years Experience.
              <br />
              <strong>"Humble beginnings make a man modest!"</strong> This quote stands true for Mr. Sadanandan A C,
              Director, Mrc Agrotech Ltd.
              <br />
              Mr. Sadanandan A C started working in the Shivaji Roller Flour Mill Thane early age. He completed his
              graduation in B.Sc Mathematics from Mumbai University.
            </p>
          </div>
        </div>
        <div className="col-12 mb-4" data-sal="zoom-in" data-delay="100" data-sal-easing="ease-out">
          <div className="team-member">
            <h2 className="client-heading">
              MR. SUNIL KUMAR AGARWAL<span>Director</span>
            </h2>
            <p>
              28 years of experience in various consulting domains like Finance, Sales, Marketing and Business Advisory.
              He is known for his precise analysis, practical insights and razor-sharp strategy. As a Director of the
              firm, he is deeply involved in consulting with clients and associates.
            </p>
          </div>
        </div>
        <div className="col-12 mb-4" data-sal="zoom-in" data-delay="150" data-sal-easing="ease-out">
          <div className="team-member">
            <h2 className="client-heading">
              MR. KIRIT KUMAR SHAH<span>Director</span>
            </h2>
            <p>
              Mr. Kirit provides the overall leadership and direction for MRC Agrotech. A spirited leader in his
              sixties, he has over 30 years of experience in trading. As a veteran he is well-versed with all aspects of
              market cycles and conditions. Apart from deftly playing the role of a curator, he also guides Team MRC
              Agrotech Ltd in day-to-day operations.
            </p>
          </div>
        </div>
        <div className="col-12 mb-4" data-sal="zoom-in" data-delay="200" data-sal-easing="ease-out">
          <div className="team-member">
            <h2 className="client-heading">
              JITENDRA D. JAIN<span>Independent Director</span>
            </h2>
            <p>
              Jitendraji, with his vast experience of 18 years in sales, marketing and administration serves as an
              Independent Director. He is involved in guiding the frontline team in business development matters and
              also provides his valuable opinions to the management board.
            </p>
          </div>
        </div>
        <div className="col-12 mb-4" data-sal="zoom-in" data-delay="200" data-sal-easing="ease-out">
          <div className="team-member">
            <h2 className="client-heading">
              NILABMBEN VIJAYKUMAR MEHRA<span>Independent Director</span>
            </h2>
            <p>
              Nilamji brings the youthful energy to the team, as she takes care of operations and sourcing. Currently
              serving MRC as an Independent Director, she also looks after the customer care functions.
            </p>
          </div>
        </div>
        <div className="col-12 mb-4" data-sal="zoom-in" data-delay="200" data-sal-easing="ease-out">
          <div className="team-member">
            <h2 className="client-heading">
              DR. KRISHNA DEV SHRIVASTAV
              <span>
                Mentor
                <br />
                Research and Development
                <br />
                (Agri Business &amp; Herbal Products){' '}
              </span>
            </h2>
            <p>
              Dr. Krishnaji leads the innovation vertical with Elan. He looks after all the futuristic endeavors of MRC
              Agrotech and provides valuable guides in refining the offering for clients as well as vendors and
              associates. He has got his ears to the ground and is constantly upgrading his knowledge base. As a part of
              the core leadership team, Dr. Krishnaji also provides guidance on improving systems and Processes to
              benefit the clients.
            </p>
          </div>
        </div>
      </div>
      <section className="text-center">
        <h1 data-sal="slide-up">CS Details</h1>
        <p data-sal="zoom-in" data-sal-easing="ease-out"><strong>Rahul Mathur</strong> is appointed Company Secretary and Compliance Officer of the company.</p>
      </section>
    </Layout>
  );
};

export default ManagementPage;
